body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

input[type="text"] {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.language-buttons {
  text-align: center;
  margin-bottom: 20px;
}

.language-buttons button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #007bff;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.language-buttons button:hover {
  background-color: #0056b3;
}

.character-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.character-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.character-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.character-card p {
  margin: 10px 0 0;
  font-weight: bold;
}

form {
  margin-bottom: 20px;
}

select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.question-form button {
  margin-top: 10px;
}

.question-form select {
  margin-top: 10px;
}

.message {
  text-align: center;
  font-size: 18px;
  color: #333;
  margin: 20px 0;
}

/* עיצוב לרשימת השיאים */
.top-scores {
  margin-top: 20px;
  padding: 0;
  list-style-type: none;
}

.top-scores h2 {
  text-align: center;
  color: #333;
}

.top-scores li {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-scores .score-info {
  font-size: 14px;
  color: #555;
}

.top-scores .score-date {
  font-size: 12px;
  color: #999;
}
